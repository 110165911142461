import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonContent, IonGrid, IonProgressBar } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { RegistrationService } from '../../../services/registration.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectedAccountsService } from '../../account/connected-accounts/connected-accounts.service';

@Component({
  selector: 'app-partial-match',
  templateUrl: './partial-match-modal.component.html',
  styleUrls: ['./partial-match-modal.component.scss'],
  imports: [IonContent, NgClass, TranslateModule, AsyncPipe, ReactiveFormsModule, IonGrid, IonProgressBar],
  providers: [RegistrationService, ConnectedAccountsService],
  standalone: true,
})
export class PartialMatchModal {
  @Input() matchData?: any;
  @Input() isConnectedAccountFlow?: boolean = false;
  radioGroup = new FormGroup({
    answer: new FormControl(null, Validators.required),
  });
  backUrl?: string;
  isLoading = false;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly authService: AuthService,
    private readonly connectedAccountsService: ConnectedAccountsService,
  ) {}

  async onContinue(): Promise<void> {
    if (this.radioGroup.invalid) return;
    this.isLoading = true;
    const token = await this.authService.getToken();

    if (this.isConnectedAccountFlow) {
      await this.createOrMatch(token!);
    } else {
      await this.matchPatient();
    }
  }

  private async createOrMatch(token: string): Promise<void> {
    const data = {
      ...this.matchData,
      answer: {
        questionId: this.matchData.question.id,
        answerId: this.radioGroup.controls['answer'].value,
      },
    };

    this.connectedAccountsService.createOrMatchPatient(data, token);
    this.isLoading = false;
  }

  async matchPatient(): Promise<void> {
    const res = await this.registrationService.matchPatient({
      patientDetails: {
        ...this.matchData.patient,
      },
      answer: {
        questionId: this.matchData.question.id,
        answerId: this.radioGroup.controls['answer'].value,
      },
    });
    this.isLoading = false;

    if (res.status !== 200 && res.error) {
      await this.registrationService.openGeneralErrorModal(res.error.message);
    }
  }
}
