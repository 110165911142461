<div class="d-flex flex-column justify-content-between h-100 px-5 py-6 bg-n0 mt-9 mt-md-0">
  <button
    type="button"
    [attr.aria-label]="'getCare.inPerson.close' | translate"
    class="btn btn-link p-0 ms-auto"
    (click)="onClose()"
  >
    <span class="icon-close text-n80 fs-24"></span>
  </button>
  <div>
    <h1 class="heading-32 text-center mb-5 pb-1 fw-600 text-wrap-balance g2">
      {{ "globalErrors.somethingWentWrong" | translate }}
    </h1>
    <p class="p-20 mb-0 text-center">{{ message }}</p>
  </div>
  <div>
    <a
      type="button"
      href="tel:8329576200"
      class="btn btn-outline rounded-pill fs-18 lh-1 py-5 w-100 mb-3"
    >
      {{ "registration.actions.contactUs" | translate }}
    </a>
    <button
      type="button"
      class="btn btn-primary rounded-pill fs-18 lh-1 py-5 w-100"
      (click)="signOut()"
    >
      {{ "registration.actions.signInAgain" | translate }}
    </button>
  </div>
</div>
