import { computed, Injectable, signal } from '@angular/core';
import { NavController } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { PartialMatchModal } from '../../registration/partial-match/partial-match-modal.component';
import { ProfilesService } from 'src/app/services/profiles.service';

@Injectable()
export class ConnectedAccountsService {
  private readonly _invite = signal<any>(undefined);
  invite = computed(this._invite);

  private readonly _connectedAccount = signal<any>(undefined);
  connectedAccount = computed(this._connectedAccount);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly modalService: ModalService,
    private readonly navController: NavController,
    private readonly activatedRoute: ActivatedRoute,
    private readonly profilesService: ProfilesService,
  ) {}

  setConnectedAccount(acc: any): void {
    this._connectedAccount.set(acc);
  }

  setInvite(invite: any): void {
    this._invite.set(invite);
  }

  async createOrMatchPatient(data: any, token: string): Promise<any> {
    try {
      const matchingResponse: any = (await firstValueFrom(
        this.httpClient.post(`${environment.ollaApiUrl}/api/supertokens/account/create-or-match`, data, {
          headers: {
            'content-type': 'application/json',
            'X-TENANT-ID': environment.orgSlug,
            Authorization: `Bearer ${token}`,
          },
        }),
      )) as any;

      let matchData: any = { ...matchingResponse };
      if (!matchData || matchData.question || Object.keys(matchData).length === 0) {
        matchData = {
          ...matchData,
          relationship: data.relationship,
          patient: {
            firstName: data.firstName,
            lastName: data.lastName,
            dateOfBirth: data.dateOfBirth,
            sexAssignedAtBirth: data.sexAssignedAtBirth,
          },
        };
      }

      this.resolveMatchData(matchData, matchingResponse, data);
    } catch (error: any) {
      this.resolveError(error);
    }
  }

  private async resolveError(errorResponse: any): Promise<void> {
    this.modalService.dismissAll();
    if (errorResponse?.error?.error?.code) {
      const code = errorResponse.error.error.code;
      switch (code) {
        case 1000:
        case 1001:
          await this.navController.navigateForward(['registration/already-verified']);
          break;
        case 1004:
        case 1005:
        case 1006:
          await this.navController.navigateForward(['registration/unable-to-match']);
          break;
      }
      return undefined;
    }
    return errorResponse;
  }

  private async resolveMatchData(matchData: any, matchingResponse: any, data: any): Promise<void> {
    // Case: partial-match - security question
    if (matchData.question) {
      this.setInvite({
        ...data,
        relationship: data.relationship,
        question: matchingResponse.question,
      });
      await this.modalService.openModal(PartialMatchModal, {
        matchData,
        isConnectedAccountFlow: true,
      });
      return;
    }
    // Case: match - requesting access to profile
    if (!matchData.wasCreated) {
      this.setInvite({
        ...data,
        relationship: data.relationship,
        name: `${data.firstName} ${data.lastName}`,
      });
      await this.navController.navigateForward(['..', 'request-access'], {
        animated: false,
        relativeTo: this.activatedRoute,
      });
      this.modalService.dismissAll();
      return;
    }
    // Case: no-match - creating a new account
    if (matchData.wasCreated) {
      this.setConnectedAccount({
        ...data,
        relationship: data.relationship,
        name: `${data.firstName} ${data.lastName}`,
      });
      this.modalService.dismissAll();
      this.profilesService.updateForceFetch(true);
      await this.navController.navigateForward(['registration/profile-setup-confirmation'], {
        animated: false,
        queryParams: {
          patientName: data.firstName,
        },
      });
      this.modalService.dismissAll();
      return;
    }
  }
}
