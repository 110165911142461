import { Component, Input } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-general-error-modal',
  templateUrl: './general-error-modal.component.html',
  standalone: true,
  imports: [IonContent, TranslateModule],
})
export class GeneralErrorModalComponent {
  @Input() message!: string;

  constructor(
    private modalCtrl: ModalController,
    private readonly authService: AuthService,
  ) {}

  onClose(): void {
    this.modalCtrl.dismiss();
  }

  signOut(): void {
    this.authService.signOut();
  }
}
