<ion-content [fullscreen]="true">
  <ion-grid
    fixed
    class="grid min-h-100 pt-8 pb-10 px-5"
  >
    @if (matchData) {
      <div>
        <span class="d-flex justify-content-center text-n90 text-center mb-6">{{
          "login.subtitle.verification" | translate
        }}</span>
        <h1 class="heading-24 mb-6 text-wrap-balance">
          {{ "registration.title.confirmDetails" | translate: { name: matchData.patient.firstName } }}
        </h1>
        <p class="p-20 mb-10 text-wrap-balance">{{ matchData.question.text }}</p>
        <form [formGroup]="radioGroup">
          @for (option of matchData.question.options; track option; let last = $last; let i = $index) {
            <div
              [ngClass]="{ 'mb-6': !last }"
              class="wrapper border p-5 rounded-1 border-n100"
            >
              <input
                [id]="'question_' + matchData.question.id + 'id_' + i"
                [value]="option.id"
                formControlName="answer"
                type="radio"
              />
              <label
                [for]="'question_' + matchData.question.id + 'id_' + i"
                class="fs-20"
              >
                {{ option.text }}
              </label>
            </div>
          }
        </form>
      </div>
      <button
        type="button"
        [disabled]="radioGroup.invalid"
        class="btn btn-primary rounded-pill fs-18 lh-1 fw-600 py-5 mt-6"
        (click)="onContinue()"
      >
        {{ "registration.actions.continue" | translate }}
      </button>
    }
  </ion-grid>
</ion-content>
