import { computed, Injectable, signal } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { GeneralErrorModalComponent } from '../shared/general-error-modal/general-error-modal.component';
import { PartialMatchModal } from '../pages/registration/partial-match/partial-match-modal.component';
import { ModalService } from './modal.service';
import { Params } from '@angular/router';
import { SupertokensApiService } from '@libs/api-services/supertokens-api.service';

export enum MatchEnum {
  NO_MATCH = 'no-match',
  PARTIAL_MATCH = 'partial-match',
  MATCH = 'match',
}

@Injectable()
export class RegistrationService {
  private readonly _matchData = signal<any>(undefined);
  matchData = computed(this._matchData);

  constructor(
    private readonly navCtrl: NavController,
    private readonly modalController: ModalController,
    private readonly modalService: ModalService,
    private readonly supertokensApiService: SupertokensApiService,
  ) {}

  updateMatchData(newData: any): void {
    this._matchData.update((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  async matchPatient(data: any, shouldNavigate: boolean = true, queryParams?: Params): Promise<any> {
    try {
      const res: any = await this.supertokensApiService.matchPatient(data, true);

      let matchData = { ...res };
      if (!matchData || matchData.question || Object.keys(matchData).length === 0) {
        matchData = {
          ...matchData,
          patient: {
            firstName: data.patientDetails.firstName,
            lastName: data.patientDetails.lastName,
            dateOfBirth: data.patientDetails.dateOfBirth,
            sexAssignedAtBirth: data.patientDetails.sexAssignedAtBirth,
            pronouns: data.patientDetails.pronouns,
          },
        };
      }
      this.updateMatchData(matchData);

      const matchEnum = this.doesPatientMatch(res);
      switch (matchEnum) {
        case MatchEnum.MATCH:
          if (shouldNavigate) {
            await this.navCtrl.navigateForward(['home'], {
              animated: false,
              queryParams,
            });
          }
          this.modalService.dismissAll();
          break;
        case MatchEnum.PARTIAL_MATCH:
          this.modalService.openModal(PartialMatchModal, { matchData });
          break;
        case MatchEnum.NO_MATCH:
          if (shouldNavigate) {
            await this.navCtrl.navigateForward(['registration/no-match'], {
              animated: false,
              queryParams,
            });
          }
          this.modalService.dismissAll();
          break;
      }

      return res;
    } catch (res: any) {
      this.modalService.dismissAll();
      if (res?.error?.error?.code) {
        const code = res.error.error.code;
        this.updateMatchData({ patient: data.patientDetails });
        switch (code) {
          case 1000:
          case 1001:
            await this.navCtrl.navigateForward(['registration/already-verified']);
            break;
          case 1004:
          case 1005:
          case 1006:
            await this.navCtrl.navigateForward(['registration/unable-to-match']);
            break;
        }
      }
      return res;
    }
  }

  doesPatientMatch(matchData: any): MatchEnum {
    if (matchData?.question) {
      return MatchEnum.PARTIAL_MATCH;
    }
    if (matchData?.patient) {
      return MatchEnum.MATCH;
    }
    return MatchEnum.NO_MATCH;
  }

  updatePatientData(data: any): void {
    this._matchData.update((oldData: any) => ({
      ...oldData,
      patient: {
        ...oldData.patient,
        ...data,
      },
    }));
  }

  async openGeneralErrorModal(message: string): Promise<void> {
    const modal = await this.modalController.create({
      component: GeneralErrorModalComponent,
      componentProps: {
        message,
      },
    });
    await modal.present();
  }
}
